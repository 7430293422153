import type { Filter, StandardFilterOption } from './analytics'
import type { Dashboard } from '~/constants/homeModules'

export interface StandardFilters {
  [title: string]: {
    options: StandardFilterOption[]
    values: StandardFilterOption[]
  }
}

export type FilterWithId = Filter & { id: string }

export type DefaultFiltersByDashboard = {
  [key in Dashboard]?: FilterWithId[]
}

export enum FilterType {
  CUSTOM_FILTER = 'CUSTOM_FILTER',
  SCOPE_FILTER = 'SCOPE_FILTER',
  DEFAULT_FILTER = 'DEFAULT_FILTER'
}
